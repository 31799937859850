import {AnchorHTMLAttributes} from "react";
import ChevronRight from '../../assets/images/chevron-right.svg'
import './styles.css'

type SideMenuButtonProps = {
    title: string
    href: AnchorHTMLAttributes<HTMLAnchorElement>["href"]
}

export default function SideMenuButton({title, href}: SideMenuButtonProps) {

    return (
        <a className="side-menu-button__container" {...{href}}>
            <span className="side-menu-button__label">{title}</span>
            <img alt={`ir para ${title}`} src={ChevronRight} />
        </a>
    )
}
