import { StyledView, Typography } from "@winecombr/blend-web";
import CardTopic from "../../components/CardTopic/CardTopic";
import { Chat, ChatApp, Mail, Phone } from "../../components/ContactCard";
import BannerTop from "../../components/BannerTop/BannerTop";
import { useSelector, useDispatch } from "react-redux";
import { getSearchResult } from "../../redux/search/searchSlice";
import { selectWidgets } from "../../redux/widgets/widgetsSlice";
import { fetchWidgets } from "../../redux/widgets/fetchWidgets";
import CardArticle from "../../components/CardArticle/CardArticle";
import { SolutionKeys } from "../../constants/solutionKeys";
import { useEffect } from "react";
import { useTrackPageView } from "../../utils/useTrackPageView";
import { shouldEmailOrChatBeDisplayed } from "../../utils/validateEmailAndChatDisplay";
import { AnyAction } from "@reduxjs/toolkit";

const Home = () => {
  const dispatch = useDispatch();
  const searchResults = useSelector(getSearchResult);
  const widgetsResults = useSelector(selectWidgets);
  const isLoadingWidgets = widgetsResults.status === "loading";

  const chatAndEmailDisplay = shouldEmailOrChatBeDisplayed(
    widgetsResults.widgets
  );

  const isMobile = window.innerWidth < 768;

  const shouldDisplayChat = () => chatAndEmailDisplay.chat;
  const shouldDisplayMail = () =>
    !chatAndEmailDisplay.chat && chatAndEmailDisplay.email;

  const filteredResults =
    Array.isArray(searchResults) && searchResults.length > 0
      ? searchResults.filter((item, index, self) => {
          return index === self.findIndex((t) => t.title === item.title);
        })
      : [];

  const { trackPageView } = useTrackPageView();

  useEffect(() => {
    document.title = `Central de Ajuda - Home`;
    trackPageView();
    dispatch(fetchWidgets() as unknown as AnyAction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <BannerTop />

      <StyledView mb={76} alignItems="center">
        <StyledView maxWidth={1100} p="0 16px">
          <Typography
            variant="heading6"
            mv="xs"
            fontSize={{ xs: 16, md: 24, lg: 32 }}
          >
            Encontre a resposta para o seu problema
          </Typography>

          <StyledView
            display="flex"
            flexWrap="wrap"
            rowGap="xxs"
            columnGap="xxs"
            flexDirection={{ xs: "column", md: "row", lg: "row" }}
            alignItems="center"
          >
            {!!filteredResults.length &&
              filteredResults.map((result, i) => (
                <CardArticle
                  key={i}
                  title={result.title}
                  categoryName={result.category_name}
                  id={result.id}
                />
              ))}
            {!searchResults.length &&
              SolutionKeys.map((solution, i) => (
                <CardTopic
                  key={i}
                  name={solution.title}
                  solutionKey={solution.key}
                  category={solution.title}
                />
              ))}
          </StyledView>

          <Typography
            variant={isMobile ? "heading6" : "heading3"}
            mt="xl"
            mb="xs"
            textAlign="center"
          >
            Não encontrou o que procura?
          </Typography>
          <StyledView alignItems="center">
            <StyledView
              width="100%"
              maxWidth={
                shouldDisplayChat() || shouldDisplayMail() ? "100%" : "700px"
              }
              rowGap="xxs"
              flexDirection={{ md: "row" }}
              justifyContent="space-between"
            >
              {!isLoadingWidgets && (
                <>
                  {shouldDisplayChat() && <Chat />}
                  {shouldDisplayMail() && <Mail />}
                </>
              )}
              <Phone />
              <ChatApp />
            </StyledView>
          </StyledView>
        </StyledView>
      </StyledView>
    </>
  );
};

export default Home;
