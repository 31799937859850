import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { fetchCategories } from './fetchCategories';
import type { CategoryState } from './category.types';

const initialState: CategoryState = {
  status: 'idle',
  categories: [],
};

export const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCategories.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(fetchCategories.fulfilled, (state, action) => {
      state.status = 'idle';
      state.categories = action.payload;
    });
    builder.addCase(fetchCategories.rejected, (state) => {
      state.status = 'error';
    });
  }

});

export const selectCategories = (state: RootState) => state.category.categories;

export default categorySlice.reducer;