import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { fetchArticles } from '../articles/fetchArticles';
import type { ArticleState, IArticle } from './articles.types';

const initialState: ArticleState = {
  status: 'idle',
  articles: [],
};

export const articleSlice = createSlice({
  name: 'article',
  initialState,
  reducers: {
    resetArticles: (state) => {
      state.articles = [] as unknown as IArticle[];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchArticles.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(fetchArticles.fulfilled, (state, action) => {
      const newArticles = action.payload.filter(article => !state.articles.some(a => a.id === article.id));
      const filteredByPlatform = newArticles.filter(({ platforms }) => platforms.includes("web") || !platforms.length)

      state.status = 'idle';
      state.articles = [...state.articles, ...filteredByPlatform];
    });
    builder.addCase(fetchArticles.rejected, (state) => {
      state.status = 'error';
    });
  }
});

export const { resetArticles } = articleSlice.actions;
export const selectArticles = (state: RootState) => state.articles.articles;
export const isLoadingArticles = (state: RootState) => state.articles.status === 'loading';

export default articleSlice.reducer;