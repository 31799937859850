/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { Loading, StyledImage, StyledView, Typography } from "@winecombr/blend-web";
import BottlesIcon from "../../assets/icons/garrafas.svg";
import { Link, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchArticle } from "../../redux/article/fetchArticle";
import { isLoadingArticle, selectArticle } from "../../redux/article/articleSlice";
import { AnyAction } from "@reduxjs/toolkit";
import BannerTop from "../../components/BannerTop/BannerTop";
import CardArticle from "../../components/CardArticle/CardArticle";
import { getSearchResult, resetArticlesSearch, resetKeyword } from "../../redux/search/searchSlice";
import { selectArticles } from "../../redux/articles/articlesSlice";
import { formatPhrase } from "../../utils/formaterPhraseSlug";
import { useTrackPageView } from "../../utils/useTrackPageView";

export default function Article() {
  const dispatch = useDispatch();
  const { id, category } = useParams();
  const article = useSelector(selectArticle);
  const isLoading = useSelector(isLoadingArticle);
  const searchResults = useSelector(getSearchResult);
  const listArticles = useSelector(selectArticles);
  const relatedArticles = listArticles.filter((articleFromList) => articleFromList.folder_id === article.folder_id);

  const renderHtmlContent = () => {
    return <div dangerouslySetInnerHTML={{ __html: article.description }} />;
  }

  function clearSearch() {
    dispatch(resetArticlesSearch());
    dispatch(resetKeyword());
  };

  const { trackPageView } = useTrackPageView();

  useEffect(() => {
    trackPageView();
    document.title = `${article.title ?? 'Central de Ajuda'} | Wine.com.br`;

    if (id) dispatch(fetchArticle(id) as unknown as AnyAction);
  }, [id, article.title]);

  if (isLoading) {
    return (
      <>
        <BannerTop />
        <StyledView alignItems="center">
          <StyledView
            maxWidth={1080}
            mv={40}
            p="md"
          >
            <Loading />
          </StyledView>
        </StyledView>
      </>
    )
  };

  return (
    <>
      <BannerTop />
      <StyledView mb={76} alignItems="center">
        <StyledView
          maxWidth={1080}
          p="0 16px"
        >
          {!!searchResults.length && (<StyledView
            display="flex"
            flexWrap="wrap"
            justifyContent="space-between"
            rowGap="xxs"
            flexDirection={{ xs: "column", md: "row" }}
            alignItems="center"
            mt="xs"
          >
            {searchResults.map((result, i) => <CardArticle key={i} title={result.title} categoryName={result.category_name} id={result.id} />)}
          </StyledView>)}

          {!searchResults.length && (
            <>
              <StyledView
                mt={40}
                bgColor="white"
                p="md"
                borderRadius="sm"
                width="100%"
              >
                <Typography variant="subtitle2" mb="xxs" color="tannat.default">
                  <Link to={`/`}>
                    <Typography variant="subtitle2" color="tannat.default">Página inicial</Typography>
                  </Link>
                  {' > '}
                  <Link to={`/category/${article.folder_id}/${category}`} onClick={clearSearch}>
                    <Typography variant="subtitle2" color="tannat.default">{category}</Typography>
                  </Link>
                  {' > '}
                  {article.title}
                </Typography>
                <Typography variant="heading3" mb="xs">{article.title}</Typography>
                <Typography variant="subtitle1" color="black">
                  {renderHtmlContent()}
                </Typography>
              </StyledView>

              {!!relatedArticles.length && <Typography variant="heading5" mt="md" mb="xxxs">Artigos relacionados</Typography>}

              {relatedArticles.map((relatedArticle, i) => {
                const slug = formatPhrase(relatedArticle.title);

                if (i < 3 && relatedArticle.id !== article.id) return (
                  <Link key={i} to={`/article/${category}/${relatedArticle.id}/${slug}`}>
                    <StyledView
                      bgColor="white"
                      p="xs"
                      flexDirection="row"
                      alignItems="center"
                      borderRadius="sm"
                      boxShadow="level-2"
                      mb="xxxs"
                    >
                      <StyledView flexDirection="row" alignItems="center">
                        <StyledImage src={BottlesIcon} width={48} />
                        <Typography
                          variant="heading5"
                          ml="nano"
                          fontSize={{ xs: 14, md: 16, lg: 18 }}
                        >
                          {relatedArticle.title}
                        </Typography>
                      </StyledView>
                    </StyledView>
                  </Link>
                )
              })}
            </>
          )}
        </StyledView>
      </StyledView>
    </>
  )
}
