import AppStoreBadge from '../../assets/images/app-store-badge.png'
import GooglePlayBadge from '../../assets/images/google-play-badge.png'
import QRCode from '../../assets/images/FooterQRCodeImage.png'
import './styles.css'
import useScreenSize from "../../hooks/useScreenSize";

type DownloadAppInfoProps = {
    id: string;
}

export default function DownloadAppInfo({id}: DownloadAppInfoProps) {

    const {deviceSize} = useScreenSize()

    function renderImage() {
        if (['phone', 'tablet'].includes(deviceSize)) {
            return (
                <div className="download-app__badges__container">
                    <a href="https://apps.apple.com/br/app/id1411629873" target="__blank">
                        <img alt="Baixe na app store" className="download-app__badge" src={AppStoreBadge}/>
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=br.com.wine.app&hl=pt_BR&gl=US" target="__blank">
                        <img alt="Baixe na play store" className="download-app__badge" src={GooglePlayBadge}/>
                    </a>
                </div>
            )
        }

        return (
            <img alt="QRCode para loja de apps" className="download-app__qrcode" src={QRCode}/>
        )
    }

    return (
        <div id={id} className="download-app__container">
            <h1 className="download-app__title">Baixe o app Wine!</h1>
            {renderImage()}
        </div>
    )
}
