import {
  Button,
  StyledImage,
  StyledTextInput,
  StyledView,
  Typography,
} from "@winecombr/blend-web";
import { IconSearch, IconClose } from "@winecombr/blend-icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSearch } from "../../redux/search/fetchSearch";
import {
  addKeyword,
  getSearchResult,
  hasErrorSearch,
  isLoadingSearch,
} from "../../redux/search/searchSlice";
import { AnyAction } from "@reduxjs/toolkit";
import useDebounce from "../../hooks/useDebounce";

export default function BannerTop() {
  const dispatch = useDispatch();
  const loadingSearch = useSelector(isLoadingSearch);
  const hasError = useSelector(hasErrorSearch);
  const search = useSelector(getSearchResult);
  const [inputValue, setInputValue] = useState("");
  const { debouncedValue } = useDebounce({ inputValue });

  function handleSearch(keyword: string) {
    const value = keyword;
    setInputValue(value);
  }

  function clearSearch() {
    setInputValue('');
    dispatch(fetchSearch('') as unknown as AnyAction);
  }

  useEffect(() => {
    dispatch(addKeyword(debouncedValue));
    dispatch(fetchSearch(debouncedValue ?? '') as unknown as AnyAction);
  }, [debouncedValue, dispatch]);

  return (
    <StyledView position="relative">
      <StyledImage
        src={'http://img.wine.com.br/central-de-atendimento/BannerCentral.svg'}
        height="30vh"
        style={{ objectFit: "cover" }}
      />
      <StyledView position="absolute" width="100%" height="100%" alignItems="center" justifyContent="center">
        <Typography
          variant="heading3"
          mb={{ xs: "xxxs", md: "xxs", lg: "xs" }}
          mh={{ xs: "xxxs", md: "nano", lg: "nano" }}
          fontSize={{ xs: 16, md: 24, lg: 32 }}
        >
          Olá, como podemos te ajudar?
        </Typography>
        <StyledView
          bgColor="white"
          flexDirection="row"
          alignItems="center"
          p="nano"
          boxShadow="level-2"
          borderRadius="sm"
          width={{ xs: 300, md: 400, lg: 600 }}
          justifyContent="space-between"
        >
          <StyledView
            flexDirection="row"
            alignItems="center"
          >
            <IconSearch size={32} />
            <StyledTextInput
              placeholder="Pesquisar artigos"
              value={inputValue}
              onChange={(e) => handleSearch(e.currentTarget.value)}
              wrapperStyle={{ borderWidth: 0, width: '100%' }}
              focused
            />
          </StyledView>
          {!!search.length && <Button onPress={clearSearch} isLoading={loadingSearch} variant="text" rightIcon={
            <IconClose size={32} />
          }>{` `}</Button>}
        </StyledView>
        {hasError && inputValue  && <Typography color="tannat.default">Ops... não encontramos nada sobre "{inputValue}"</Typography>}
      </StyledView>
    </StyledView>
  )
}