import { StyledView, Typography } from "@winecombr/blend-web";
import { Link } from "react-router-dom";
import { formatPhrase } from "../../utils/formaterPhraseSlug";
import { resetArticlesSearch, resetKeyword } from "../../redux/search/searchSlice";
import { useDispatch } from "react-redux";
import type { ICardArticleProps } from "./cardArticle.types";


export default function CardArticle({ title, categoryName, id }: ICardArticleProps) {
  const slug = formatPhrase(title);
  const dispatch = useDispatch();

  function clearSearch() {
    dispatch(resetArticlesSearch());
    dispatch(resetKeyword());
  }

  return (
    <StyledView
      width={300}
      minHeight={{ xs: 60, md: 100 }}
      bgColor="white"
      borderRadius="sm"
      flexDirection="row"
      alignItems="flex-start"
      boxShadow="level-2"
      mr="xxxs"
    >
      <Link to={`/article/${categoryName}/${id}/${slug}`} onClick={clearSearch}>
        <StyledView height="100%" p="nano" >
          <Typography variant="caption">{categoryName}</Typography>
          <Typography variant="heading6">{title}</Typography>
        </StyledView>
      </Link>
    </StyledView>
  )
}