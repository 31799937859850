import { createAsyncThunk } from "@reduxjs/toolkit";
import apiHelpCenter from "../../services/api-help-center";
import type { ICardTopicProps } from "../../components/CardTopic/cardTopic.types";

const fetchCategories = createAsyncThunk(
  'category/fetchCategories',
  async () => {
    const response = await apiHelpCenter.get('/solutions/categories')
    return (await response.data) as ICardTopicProps[];
  }
);

export { fetchCategories };