import { configureStore } from "@reduxjs/toolkit";
import categorySlice from "./categories/categorySlice";
import articlesSlice from "./articles/articlesSlice";
import articleSlice from "./article/articleSlice";
import searchSlice from "./search/searchSlice";
import widgetsSlice from "./widgets/widgetsSlice";

export const store = configureStore({
  reducer: {
    category: categorySlice,
    articles: articlesSlice,
    article: articleSlice,
    search: searchSlice,
    widgets: widgetsSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
