import axios from "axios";

const apiWidgetHelpCenter = axios.create({
  baseURL: process.env.REACT_APP_HELP_CENTER_WIDGET_URL ?? "",
  headers: {
    "Content-Type": "application/json",
  },
});

export default apiWidgetHelpCenter;
