import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { fetchSearch } from './fetchSearch';
import type { ArticleState, IArticle } from './search.types';
import { SolutionIgnored, SolutionKeys } from '../../constants/solutionKeys';

const initialState: ArticleState = {
  status: 'idle',
  articles: [] as unknown as IArticle[],
  keyword: ''
};

export const searchSlice = createSlice({
  name: 'article',
  initialState,
  reducers: {
    resetArticlesSearch: (state) => {
      state.articles = [] as unknown as IArticle[];
    },
    resetKeyword: (state) => {
      state.keyword = '';
    },
    addKeyword: (state, action) => {
      state.keyword = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSearch.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(fetchSearch.fulfilled, (state, action) => {
      const { payload } = action;
      const acceptableSolutionKeys = SolutionKeys.map(({ key }) => key);
      const payloadWithIgnoredCategory = payload.filter((article) => article.category_id !== SolutionIgnored.key && acceptableSolutionKeys.includes(article.folder_id as number));

      if (!!payloadWithIgnoredCategory.length) {
        state.status = 'idle';
        state.articles = payloadWithIgnoredCategory;

        return;
      }

      state.status = 'error';
      state.articles = [];
    });
    builder.addCase(fetchSearch.rejected, (state) => {
      state.status = 'error';
    });
  }
});

export const { resetArticlesSearch, addKeyword, resetKeyword } = searchSlice.actions;

export const getSearchResult = (state: RootState) => state.search.articles;
export const isLoadingSearch = (state: RootState) => state.search.status === 'loading';
export const hasErrorSearch = (state: RootState) => state.search.status === 'error' && state.search.keyword !== '';

export const getKeyword = (state: RootState) => state.search.keyword;

export default searchSlice.reducer;
