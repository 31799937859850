import LogoWine from "../../assets/images/LogoWine.svg";
import AccountIcon from "../../assets/images/AccountIcon.svg";
import MenuIcon from "../../assets/images/menu.svg";
import "./styles.css";
import SideMenu from "../SideMenu/SideMenu";
import { useMemo, useState } from "react";
import { formatPhrase } from "../../utils/formaterPhraseSlug";

const NavBar = () => {
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);

  const pagePath = useMemo(() => {
    return formatPhrase(window.location.pathname === '/' ? 'home' : window.location.pathname)
  }, []);

  function toggleSideMenu() {
    setIsSideMenuOpen((prevIsSideMenuOpen) => !prevIsSideMenuOpen);
  }

  return (
    <div className="navbar">
      <div className="navbar__container container">
        <div className="navbar__left">
          <button onClick={toggleSideMenu} className="navbar__left__menu-icon">
            <img alt="Abrir Menu" src={MenuIcon} />
          </button>
          <a href="https://www.wine.com.br/?utm_source=central-de-ajuda&utm_medium=logo-wine-menu&utm_campaign=permanent" className="wine-logo">
            <img src={LogoWine} alt="Logo Wine" />
          </a>
          <div className="navbar__left__links__container">
            <a href={`https://www.wine.com.br/clubewine/?utm_source=central-de-ajuda&utm_medium=${pagePath}&utm_campaign=permanent`} className="link">
              Clube
            </a>
            <a href={`https://www.wine.com.br/loja-vinhos/?utm_source=central-de-ajuda&utm_medium=${pagePath}&utm_campaign=permanent`} className="link">
              Loja
            </a>
            <a href={`https://www.wine.com.br/loja/produtor/?utm_source=central-de-ajuda&utm_medium=${pagePath}&utm_campaign=permanent`} className="link">
              Produtores
            </a>
            <a href={`https://www.wine.com.br/vinhos-promocao/?utm_source=central-de-ajuda&utm_medium=${pagePath}&utm_campaign=permanent`} className="link">
              Ofertas
            </a>
            <a
              href={`https://www.wineeventos.com.br/encontreembaixador/?utm_source=central-de-ajuda&utm_medium=${pagePath}&utm_campaign=permanent`}
              className="link"
            >
              Eventos
            </a>
            <a
              href={`https://www.wine.com.br/institucional/app/?utm_source=central-de-ajuda&utm_medium=${pagePath}&utm_campaign=permanent`}
              className="link"
            >
              Baixe o App
            </a>
          </div>
        </div>
        <div className="navbar__right">
          <a
            href="https://www.wine.com.br/sign-in.ep"
            className="account-button"
          >
            <img
              src={AccountIcon}
              alt="Informações da conta wine"
              className="accountIcon"
            />
          </a>
        </div>
      </div>
      <SideMenu onRequestClose={toggleSideMenu} open={isSideMenuOpen} />
    </div>
  );
};

export default NavBar;
