import './App.css';
import { ThemeProvider as WebThemeProvider } from "styled-components";
import { theme } from "@winecombr/blend-theme";
import { RouterProvider } from 'react-router-dom'
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { router } from './routes';
import Footer from './components/Footer/Footer';
import LegalInfo from './components/LegalInfo/LegalInfo';
import NavBar from './components/Navbar/NavBar';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM ?? '',
}

function App() {
  TagManager.initialize(tagManagerArgs);

  return (
    <WebThemeProvider theme={theme}>
      <Provider store={store}>
        <>
          <NavBar />
          <RouterProvider router={router} />
          <Footer />
          <LegalInfo />
        </>
      </Provider>
    </WebThemeProvider>
  );
}

export default App;
