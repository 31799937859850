/* eslint-disable react-hooks/exhaustive-deps */
import { Loading, StyledView, Typography } from "@winecombr/blend-web";
import { Link, useParams } from "react-router-dom";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "@reduxjs/toolkit";
import BannerTop from "../../components/BannerTop/BannerTop";
import CardArticle from "../../components/CardArticle/CardArticle";
import { getSearchResult, resetArticlesSearch } from "../../redux/search/searchSlice";
import { isLoadingArticles, selectArticles } from "../../redux/articles/articlesSlice";
import { fetchArticles } from "../../redux/articles/fetchArticles";
import { useTrackPageView } from "../../utils/useTrackPageView";

export default function Category() {
  const dispatch = useDispatch();
  const { id, slug } = useParams();
  const articles = useSelector(selectArticles);
  const isLoading = useSelector(isLoadingArticles);
  const searchResults = useSelector(getSearchResult);

  const filteredArticles = useCallback(() => {
    return articles.filter((article) => article.folder_id === Number(id))
  }, [articles, id]);

  const { trackPageView } = useTrackPageView()

  useEffect(() => {
    trackPageView();

    document.title = `${slug}`;

    dispatch(resetArticlesSearch());
    if (id) dispatch(fetchArticles({ id: Number(id) }) as unknown as AnyAction);
  }, [id, dispatch]);

  if (isLoading) {
    return (
      <>
        <BannerTop />
        <StyledView alignItems="center">
          <StyledView
            maxWidth={1080}
            mv={40}
            p="md"
          >
            <Loading />
          </StyledView>
        </StyledView>
      </>
    )
  };

  return (
    <>
      <BannerTop />
      <StyledView mb={76} alignItems="center">
        <StyledView
          maxWidth={1080}
          p="0 16px"
        >
          <StyledView
            mt={40}
            bgColor="white"
            p="md"
            borderRadius="sm"
          >
            {!!searchResults.length && (
              <>
                <Typography
                variant="heading6"
                mb={{ xs: "nano", md: "md" }}
                fontSize={{ xs: 16, md: 24, lg: 32 }}
                >
                  Encontre a resposta para o seu problema
                </Typography>
              </>
            )}
            {!!searchResults.length && (
              <StyledView
                display="flex"
                flexWrap="wrap"
                justifyContent="space-evenly"
                rowGap="xxs"
                flexDirection={{ xs: "column", md: "row", lg: "row" }}
                alignItems="center"
              >
                {searchResults.map((result, i) => <CardArticle key={i} title={result.title} categoryName={result.category_name} id={result.id} />)}
              </StyledView>
            )}

            {!searchResults.length && (
              <>
                <Typography variant="subtitle2" mb="xxs" color="tannat.default">
                  <Link to={`/`}>
                    <Typography variant="subtitle2" color="tannat.default">Página inicial</Typography>
                  </Link>
                  {' >'} {slug}
                </Typography>

                <Typography variant="heading3" mb="nano">{slug}</Typography>
                <Typography variant="subtitle1" mb="xxs" color="black">Aqui você sabe tudo sobre {slug} no Clube Wine</Typography>

                <StyledView
                  display="flex"
                  flexWrap="wrap"
                  justifyContent="space-between"
                  rowGap="xxs"
                  flexDirection={{ xs: "column", md: "row", lg: "row" }}
                  alignItems="center"
                >
                  {filteredArticles().map((article) => (
                    <CardArticle key={article.id} id={article.id} categoryName={slug ?? ''} title={article.title} />
                  ))}

                </StyledView>
              </>
            )}

          </StyledView>
        </StyledView>
      </StyledView>
    </>
  )
}