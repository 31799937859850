import FooterCertificatesImage from '../../assets/images/FooterCertificatesImage.png'
import './styles.css'

type CertificatesInfoProps = {
    id: string;
}

export default function CertificatesInfo({id}: CertificatesInfoProps) {

    return (
        <div id={id} className="certificates__container">
            <h1 className="certificates__title">Certificados</h1>
            <img alt="certificados de qualidade" className="certificates__image" src={FooterCertificatesImage}/>
        </div>
    )
}
