import DownloadAppInfo from "../DownloadAppInfo/DownloadAppInfo";
import PaymentInfo from "../PaymentInfo/PaymentInfo";
import SecurityInfo from "../SecurityInfo/SecurityInfo";
import CertificatesInfo from "../CertificatesInfo/CertificatesInfo";
import "./styles.css";

const Footer = () => {
    return (
        <div className="footer__container">
            <div className="container">
                <div className="footer__relevant-info">
                    <DownloadAppInfo id="footer__relevant-info__download-app" />
                    <PaymentInfo id="footer__relevant-info__payment" />
                    <SecurityInfo id="footer__relevant-info__security" />
                    <CertificatesInfo id="footer__relevant-info__certificates" />
                </div>
            </div>
        </div>
    );
};

export default Footer;
