import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { fetchArticle } from './fetchArticle';
import type { ArticleState, IArticle } from './article.types';

const initialState: ArticleState = {
  status: 'idle',
  article: {} as unknown as IArticle,
};

export const articleSlice = createSlice({
  name: 'article',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchArticle.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(fetchArticle.fulfilled, (state, action) => {
      state.status = 'idle';
      state.article = action.payload;
    });
    builder.addCase(fetchArticle.rejected, (state) => {
      state.status = 'error';
    });
  }
});

export const selectArticle = (state: RootState) => state.article.article;
export const isLoadingArticle = (state: RootState) => state.article.status === 'loading';

export default articleSlice.reducer;