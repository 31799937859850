import useScreenSize from "../../hooks/useScreenSize";
import { StyledView, Typography } from "@winecombr/blend-web";
import BaseContactCard from './BaseContactCard/BaseContactCard';
import ChatAppIcon from '../../assets/icons/cellphone-check.svg'
import ChatAppQRCode from '../../assets/images/chat-app-qrcode.png'


export default function ChatAppContactCard() {
  const { deviceSize } = useScreenSize()

  const isMobile = /phone|tablet/.test(deviceSize)

  function renderLeftElement() {
    if (isMobile) return <img src={ChatAppIcon} alt="Chat no telefone" />

    return <img src={ChatAppQRCode} alt="qrcode para acessar o chat dentro do app" />
  }

  return isMobile ? (
    <BaseContactCard
      title="Ajuda no App"
      subtitle="Central de Ajuda"
      leftElement={renderLeftElement}
      href="appwine://appwine?openHelpCenter=FAQs"
    />
  ) : (
    <StyledView
      p="xs"
      width="100%"
      maxHeight={80}
      bgColor="white"
      borderRadius="md"
      flexDirection="row"
      alignItems="center"
      boxShadow="level-2"
      maxWidth={{ md: 343 }}
    >
     {renderLeftElement()}
      <StyledView ml="xxxs">
        <Typography variant="heading6">Ajuda no App</Typography>
        <Typography variant="paragraphSmall">Escaneie o QR Code</Typography>
      </StyledView>
    </StyledView>
  )
}
