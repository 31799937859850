export const initialValues = {
  id: 1,
  created_at: "2024-06-26T17:25:38.344Z",
  updated_at: "2024-06-26T17:25:38.344Z",
  Domingo: {
    id: 1,
    horarioDeInicioEmail: null,
    horarioTerminoEmail: null,
    horarioInicioChat: null,
    horarioTerminoChat: null,
    habilitarChat: false,
    habilitarEmail: false,
  },
  Segunda: {
    id: 2,
    horarioDeInicioEmail: "06:00:00",
    horarioTerminoEmail: "20:00:00",
    horarioInicioChat: "08:00:00",
    horarioTerminoChat: "18:00:00",
    habilitarChat: false,
    habilitarEmail: false,
  },
  Terca: {
    id: 3,
    horarioDeInicioEmail: "06:00:00",
    horarioTerminoEmail: "20:00:00",
    horarioInicioChat: "08:00:00",
    horarioTerminoChat: "18:00:00",
    habilitarChat: false,
    habilitarEmail: false,
  },
  Quarta: {
    id: 4,
    horarioDeInicioEmail: "06:00:00",
    horarioTerminoEmail: "20:00:00",
    horarioInicioChat: "08:00:00",
    horarioTerminoChat: "18:00:00",
    habilitarChat: false,
    habilitarEmail: false,
  },
  Quinta: {
    id: 5,
    horarioDeInicioEmail: "06:00:00",
    horarioTerminoEmail: "20:00:00",
    horarioInicioChat: "08:00:00",
    horarioTerminoChat: "18:00:00",
    habilitarChat: false,
    habilitarEmail: false,
  },
  Sexta: {
    id: 6,
    horarioDeInicioEmail: "06:00:00",
    horarioTerminoEmail: "20:00:00",
    horarioInicioChat: "08:00:00",
    horarioTerminoChat: "18:00:00",
    habilitarChat: false,
    habilitarEmail: false,
  },
  Sabado: {
    id: 7,
    horarioDeInicioEmail: null,
    horarioTerminoEmail: null,
    horarioInicioChat: null,
    horarioTerminoChat: null,
    habilitarChat: false,
    habilitarEmail: false,
  },
};
