import './styles.css'
import WLogo from "../../assets/images/WLogo.svg";

export default function LegalInfo() {
    return (
        <div className="legal-info__container">
            <div className="legal-info__company-info__container">
                <div className="legal-info__company-info">
                <img src={WLogo} alt="Logo Wine" className="legal-info__wine-logo"/>
                    <div>
                    <h3 className="legal-info__company-info__name">W2W E-Commerce de Vinhos S.A</h3>
                    <p>
                        CNPJ: 09.813.204/0002-05
                    </p>
                    </div>
                </div>
                <address className="legal-info__company-info__address">
                    <p>Rua Comendador Alcides Simão Helou, 1478</p>
                    <p>Serra - ES - CEP 29168-090</p>
                </address>
            </div>
            <div className="legal-info__drinking__container">
                <p className="legal-info__drinking__description">
                    SE BEBER, NÃO DIRIJA. APRECIE COM MODERAÇÃO. A VENDA DE BEBIDAS
                    ALCOÓLICAS É PROIBIDA PARA MENORES DE 18 ANOS.
                </p>
            </div>
        </div>
    )
}
