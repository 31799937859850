import PaymentTypesImage from '../../assets/images/FooterPaymentImage.png'
import './styles.css'

type PaymentInfoProps = {
    id: string;
}

export default function PaymentInfo({id}: PaymentInfoProps) {
    return (
        <div id={id} className="payment__container">
            <h1 className="payment__title">Pagamentos</h1>
            <img alt="tipos de pagamento aceitos" className="payment__image" src={PaymentTypesImage} />
        </div>
    )
}
