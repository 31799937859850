import Home from "../screens/Home/Home";
import Article from "../screens/Article/Article";
import { createHashRouter } from "react-router-dom";
import Category from "../screens/Category/Category";

export const router = createHashRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/article/:category/:id/:slug",
    element: <Article />,
  },
  {
    path: "/category/:id/:slug",
    element: <Category />,
  },
]);
