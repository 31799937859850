import BaseContactCard from "./BaseContactCard/BaseContactCard";
import LetterIcon from "../../assets/icons/email.svg";
import { StyledImage } from "@winecombr/blend-web";

export default function MailContactCard({ id }: { id?: string }) {
  function onClick() {
    if (window.fcWidget) {
      window.fcWidget.open()
    } else {
      // @ts-ignore
      FreshworksWidget('open')
    }
  }

  return (
    <BaseContactCard
      id={id}
      className="mail-contact-card__container"
      title="Atendimento via E-mail"
      subtitle="Preencha o formulário"
      leftElement={
        <StyledImage alt="Ícone de Carta" src={LetterIcon} />
      }
      onClick={onClick}
    />
  )
}
