import { createAsyncThunk } from "@reduxjs/toolkit";
import apiHelpCenter from "../../services/api-help-center";
import type { IArticle, FetchArticlesParams } from "./articles.types";

const fetchArticles = createAsyncThunk(
  'article/fetchArticles',
  async (params: FetchArticlesParams) => {
    const { id, page = 1, perPage = 100 } = params

    const response = await apiHelpCenter.get(`/solutions/folders/${id}/articles?page=${page}&per_page=${perPage}`)
    return response.data as IArticle[];
  }
);

export { fetchArticles };