import { useEffect, useState } from "react";

interface useDebounceProps {
  inputValue: string;
  time?: number
}

export default function useDebounce({ inputValue, time = 1500 }: useDebounceProps) {
  const [debouncedValue, setDebouncedValue] = useState("");

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedValue(inputValue);
    }, time);

    return () => clearTimeout(timerId);
  }, [inputValue, time]);

  return { debouncedValue };
}
