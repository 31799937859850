import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { fetchWidgets } from "./fetchWidgets";
import type { WidgetStore } from "./widgets.types";
import { initialValues } from "./widgetsInitialValue";

const initialState: WidgetStore = {
  status: "idle",
  widgets: initialValues,
};

export const widgetsSlice = createSlice({
  name: "widgets",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchWidgets.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchWidgets.fulfilled, (state, action) => {
      state.status = "idle";
      state.widgets = action.payload;
    });
    builder.addCase(fetchWidgets.rejected, (state) => {
      state.status = "error";
    });
  },
});

export const selectWidgets = (state: RootState) => state.widgets;

export default widgetsSlice.reducer;
