import {useEffect, useState} from "react";


type ScreenSize = {
    width: number,
    height: number,
    deviceSize: "phone" | "tablet" | "desktop" | "large-desktop"
}

function getCurrentDeviceSize(width: number): ScreenSize["deviceSize"] {
    if (width < 576) return "phone"

    if (width >= 576 && width < 768) return "tablet"

    if (width >= 768 && width < 1200) return "desktop"

    return "large-desktop"
}

export default function useScreenSize(): ScreenSize {
    const WINDOW_WIDTH = window.innerWidth
    const WINDOW_HEIGHT = window.innerHeight;

    const [sizes, setSizes] = useState({
        width: WINDOW_WIDTH,
        height: WINDOW_HEIGHT,
        deviceSize: getCurrentDeviceSize(WINDOW_WIDTH)
    })

    useEffect(() => {
        let timeout: NodeJS.Timeout | undefined = undefined;

        function windowResizeHandler(event: UIEvent) {

            if (timeout) clearTimeout(timeout)

            timeout = setTimeout(() => {

                const {innerWidth: width, innerHeight: height} = event.target as Window;

                const nextSizes = {
                    width,
                    height,
                    deviceSize: getCurrentDeviceSize(width)
                }

                setSizes(nextSizes)
            }, 200)
        }


        window.addEventListener("resize", windowResizeHandler)
    }, [setSizes])

    return sizes;
}
