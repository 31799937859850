import BaseContactCard from "./BaseContactCard/BaseContactCard";
import MessageIcon from "../../assets/icons/balloon-help.svg";
import { StyledImage } from "@winecombr/blend-web";

export default function ChatContactCard() {
  function onClick() {
    if (window.fcWidget) {
      window.fcWidget.open()
    } else {
      // @ts-ignore
      FreshworksWidget('open')
    }
  }

  return (
    <BaseContactCard
      onClick={onClick}
      title="Chat Online"
      subtitle="Seg. à Sex. de 09h às 18h"
      // TODO: use Image instead of StyledImage in the whole project
      leftElement={
        <StyledImage src={MessageIcon} alt="Ícone de balão de conversa" />
      }
    />
  )
}
