import { createAsyncThunk } from "@reduxjs/toolkit";
import apiHelpCenter from "../../services/api-help-center";
import type { IArticle } from "./article.types";

const fetchArticle = createAsyncThunk(
  'article/fetchArticle',
  async (id: string | number) => {
    const response = await apiHelpCenter.get(`/solutions/articles/${id}`)
    return (await response.data) as IArticle;
  }
);

export { fetchArticle };