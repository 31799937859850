import { createAsyncThunk } from "@reduxjs/toolkit";
import apiWidgetHelpCenter from "../../services/api-widget-help-center";
import type { Days } from "./widgets.types";

const fetchWidgets = createAsyncThunk("widgets/fetchWidgets", async () => {
  const response = await apiWidgetHelpCenter.get("/help-center-widget.json");
  return response.data as Days;
});

export { fetchWidgets };
