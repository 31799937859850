interface DayConfig {
  id: number;
  horarioDeInicioEmail: string | null;
  horarioTerminoEmail: string | null;
  horarioInicioChat: string | null;
  horarioTerminoChat: string | null;
  habilitarChat: boolean;
  habilitarEmail: boolean;
}

interface Schedule {
  id: number;
  created_at: string;
  updated_at: string;
  Domingo: DayConfig;
  Segunda: DayConfig;
  Terca: DayConfig;
  Quarta: DayConfig;
  Quinta: DayConfig;
  Sexta: DayConfig;
  Sabado: DayConfig;
}

export function shouldEmailOrChatBeDisplayed(schedule: Schedule): {
  email: boolean;
  chat: boolean;
} {
  const daysOfWeek = [
    "Domingo",
    "Segunda",
    "Terca",
    "Quarta",
    "Quinta",
    "Sexta",
    "Sabado",
  ];
  const today = new Date();
  const currentDayOfWeek = daysOfWeek[today.getDay()];
  const currentTime = today.toTimeString().split(" ")[0];

  const dayConfig = schedule[currentDayOfWeek as keyof Schedule] as DayConfig;

  const isEmailEnabled =
    dayConfig.habilitarEmail &&
    dayConfig.horarioDeInicioEmail &&
    dayConfig.horarioTerminoEmail
      ? currentTime >= dayConfig.horarioDeInicioEmail &&
        currentTime <= dayConfig.horarioTerminoEmail
      : false;

  const isChatEnabled =
    dayConfig.habilitarChat &&
    dayConfig.horarioInicioChat &&
    dayConfig.horarioTerminoChat
      ? currentTime >= dayConfig.horarioInicioChat &&
        currentTime <= dayConfig.horarioTerminoChat
      : false;

  return { email: isEmailEnabled, chat: isChatEnabled };
}

// const schedule: Schedule = {
//   id: 1,
//   created_at: "2024-06-25T17:30:32.767Z",
//   updated_at: "2024-06-25T17:30:32.767Z",
//   Domingo: {
//     id: 3,
//     horarioDeInicioEmail: null,
//     horarioTerminoEmail: null,
//     horarioInicioChat: null,
//     horarioTerminoChat: null,
//     habilitarChat: false,
//     habilitarEmail: false,
//   },
//   Segunda: {
//     id: 1,
//     horarioDeInicioEmail: "06:00:00",
//     horarioTerminoEmail: "20:00:00",
//     horarioInicioChat: "09:00:00",
//     horarioTerminoChat: "18:00:00",
//     habilitarChat: true,
//     habilitarEmail: true,
//   },
//   Terca: {
//     id: 4,
//     horarioDeInicioEmail: "06:00:00",
//     horarioTerminoEmail: "20:00:00",
//     horarioInicioChat: "09:00:00",
//     horarioTerminoChat: "18:00:00",
//     habilitarChat: true,
//     habilitarEmail: true,
//   },
//   Quarta: {
//     id: 5,
//     horarioDeInicioEmail: "06:00:00",
//     horarioTerminoEmail: "07:00:00",
//     horarioInicioChat: "09:00:00",
//     horarioTerminoChat: "18:00:00",
//     habilitarChat: true,
//     habilitarEmail: true,
//   },
//   Quinta: {
//     id: 6,
//     horarioDeInicioEmail: "06:00:00",
//     horarioTerminoEmail: "20:00:00",
//     horarioInicioChat: "09:00:00",
//     horarioTerminoChat: "18:00:00",
//     habilitarChat: true,
//     habilitarEmail: true,
//   },
//   Sexta: {
//     id: 7,
//     horarioDeInicioEmail: "06:00:00",
//     horarioTerminoEmail: "20:00:00",
//     horarioInicioChat: "09:00:00",
//     horarioTerminoChat: "18:00:00",
//     habilitarChat: true,
//     habilitarEmail: true,
//   },
//   Sabado: {
//     id: 8,
//     horarioDeInicioEmail: null,
//     horarioTerminoEmail: null,
//     horarioInicioChat: null,
//     horarioTerminoChat: null,
//     habilitarChat: false,
//     habilitarEmail: false,
//   },
// };
