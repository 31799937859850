import { createAsyncThunk } from "@reduxjs/toolkit";
import apiHelpCenter from "../../services/api-help-center";
import type { IArticle } from "./search.types";

const fetchSearch = createAsyncThunk(
  'article/fetchSearch',
  async (keyword: string) => {
    const response = await apiHelpCenter.get(`/search/solutions?term=${keyword}`)
    return (await response.data) as IArticle[];
  }
);

export { fetchSearch };