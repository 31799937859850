import SecurityImage from '../../assets/images/FooterSecurityImage.png'
import './styles.css'

type SecurityInfoProps = {
    id: string;
}

export default function SecurityInfo({id}: SecurityInfoProps) {
    return (
        <div id={id} className="security__container">
            <h1 className="security__title">Segurança</h1>
            <img alt="selo de segurança" className="security__image" src={SecurityImage} />
        </div>
    )
}
