import { isValidElement } from "react";
import { StyledView, Typography } from "@winecombr/blend-web";
import { Link } from "react-router-dom";
import type { ContactCardProps } from "./BaseContactCard.types";

export default function BaseContactCard({ title, subtitle, leftElement, className, ...props }: ContactCardProps) {

  function renderLeftElement() {
    if (typeof leftElement === 'function') return leftElement()

    if (isValidElement(leftElement)) {
      return leftElement
    }

    return null
  }

  return (
    <Link onClick={props.onClick} to={props.href ?? '/'}>
      <StyledView
        minWidth={343}
        maxHeight={80}
        bgColor="white"
        borderRadius="md"
        flexDirection="row"
        alignItems="center"
        p="xs"
        className={className}
        cursor='pointer'
        boxShadow="level-2"
      >
        {renderLeftElement()}
        <StyledView ml="xxxs">
          <Typography variant="heading6">{title}</Typography>
          <Typography variant="paragraphSmall">{subtitle}</Typography>
        </StyledView>
      </StyledView>
    </Link>
  )
}
