import BaseContactCard from "./BaseContactCard/BaseContactCard";
import PhoneIcon from "../../assets/icons/cancelamento-continuar.svg";
import { StyledImage } from "@winecombr/blend-web";

export default function PhoneContactCard() {
  return (
    <BaseContactCard
      className="phone-contact-card__container"
      href="tel:02732022600"
      title="Telefone (27) 3202-2600"
      subtitle="Seg. à Sex. de 09h às 18h"
      leftElement={
        <StyledImage src={PhoneIcon} alt="Icone de telefone" />
      }
    />
  )
}
