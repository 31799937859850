import axios from "axios";

const apiHelpCenter = axios.create({
    baseURL: "https://atendimentowine.freshdesk.com/api/v2/",
    headers: {
      "Content-Type": "application/json",
    }
});

apiHelpCenter.defaults.auth = {
  username: process.env.REACT_APP_FRESHDESK_API_KEY ?? "",
  password: 'X'
}

export default apiHelpCenter;