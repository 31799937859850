/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-comment-textnodes */
import { IconArrowRight } from "@winecombr/blend-icons";
import { Loading, StyledView, Typography } from "@winecombr/blend-web";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isLoadingArticles, selectArticles } from "../../redux/articles/articlesSlice";
import { formatPhrase } from "../../utils/formaterPhraseSlug";
import { fetchArticles } from "../../redux/articles/fetchArticles";
import { AnyAction } from "@reduxjs/toolkit";

export default function CardTopic({ name, solutionKey, category }: { name: string, solutionKey: number, category: string }) {
  const dispatch = useDispatch();
  const articles = useSelector(selectArticles);
  const isLoadingArticle = useSelector(isLoadingArticles);

  const filteredArticles = articles.filter((article) => article.folder_id === solutionKey);

  useEffect(() => {
    if (solutionKey) dispatch(fetchArticles({ id: solutionKey }) as unknown as AnyAction);
  }, [dispatch, solutionKey]);

  if (isLoadingArticle) {
    return (
      <StyledView
        width={300}
        minHeight={224}
        bgColor="white"
        borderRadius="sm"
        p={18}
        boxShadow="level-2"
        alignItems="center"
        justifyContent="center"
      >
        <Loading />
      </StyledView>
    )
  }

  return (
    <StyledView
      width={336}
      minHeight={224}
      bgColor="white"
      borderRadius="sm"
      p={18}
      boxShadow="level-2"
      justifyContent="space-between"
    >
      <StyledView >
        <Typography variant="heading6" fontSize="lg" mb="xxxs">{name}</Typography>

        {filteredArticles.map((article, i) => {
          const slug = formatPhrase(article.title);
          if (i < 2)
            return (
              <Link key={i} to={`/article/${category}/${article.id}/${slug}`}>
                <StyledView
                  flexDirection="row"
                  justifyContent="space-between"
                  mb="nano"
                >
                  <Typography variant="paragraphSmall" fontSize="md">{article.title}</Typography>
                  <StyledView ml="nano" pt="nano">
                    <IconArrowRight color="tannat.default" size={16} />
                  </StyledView>
                </StyledView>
              </Link>
            )
        })}
      </StyledView>

      <Link to={`/category/${solutionKey}/${category}`}>
        <StyledView
          flexDirection="row"
          alignItems="center"
        >
          <Typography variant="labelSmall" color="tannat.default" mr="nano">Ver mais artigos</Typography>
          <IconArrowRight color="tannat.default" size={16} />
        </StyledView>
      </Link>
    </StyledView>
  );
}
