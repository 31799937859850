import {Fragment} from 'react'
import AccountIcon from "../../assets/images/AccountIcon.svg";
import RightArrowIcon from "../../assets/images/right-arrow.svg"
import CloseIcon from "../../assets/images/close.svg"
import './styles.css'
import SideMenuButton from "../SideMenuButton/SideMenuButton";

const MENU_ITEMS = [
    {
        title: 'CLUBE',
        href: "https://www.wine.com.br/clubewine",
    }, {
        title: "LOJA",
        href: "https://www.wine.com.br/loja-vinhos"
    }, {
        title: "PRODUTORES",
        href: "https://www.wine.com.br/loja/produtor/"
    }
    , {
        title: "OFERTAS",
        href: "https://www.wine.com.br/vinhos-promocao"
    }
    , {
        title: "EVENTOS",
        href: "https://www.wineeventos.com.br/encontreembaixador/"
    }
    , {
        title: "BAIXE O APP",
        href: "https://www.wine.com.br/institucional/app/"
    }

]

type SideMenuProps = {
    open: boolean;
    onRequestClose: () => void;
}

export default function SideMenu({open = false, onRequestClose}: SideMenuProps) {
    return (
        <Fragment>
            <span onClick={onRequestClose} data-toggle={open} className="side-menu__overlay"/>
            <div data-toggle={open} className="side-menu__container">
                <div className="side-menu__header">
                    <img className="side-menu__header__image" alt="imagem de perfil" src={AccountIcon}/>
                    <div className="side-menu__header__description__container">
                        <span className="side-menu__header__description">Acesse sua conta</span>
                        <a href="https://www.wine.com.br/sign-in.ep#/identificacao" className="side-menu__header__sign-in">
                            ENTRAR
                            <img alt="entrar na conta" className="side-menu__header__sign-in__icon" src={RightArrowIcon}/>
                        </a>
                    </div>
                    <button className="side-menu__header__close-button" onClick={onRequestClose}>
                        <img alt="fechar menu lateral" src={CloseIcon} />
                    </button>
                </div>
                <div className="side-menu__content-container">
                {MENU_ITEMS.map((item, index, items) => {
                    const isLastItem = index === items.length - 1;

                    return (
                        <Fragment key={index}>
                            <SideMenuButton {...item} />
                            {!isLastItem && <div className="side-menu__content-container__separator" />}
                        </Fragment>
                    )
                })}
                </div>
            </div>
        </Fragment>
    )
}
