/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { MemoryRouter } from 'react-router-dom';

export function useTrackPageView() {
  const { props } = MemoryRouter({
    initialEntries: [window.location.pathname],
  });

  useEffect(() => {
    props.navigator.listen(trackPageView);
  }, [])

  function trackPageView() {
    ReactGA.set({
      hitType: 'pageview',
      page: `${document.location.hostname}/${window.location.pathname + window.location.search}`,
    });
    ReactGA.send({
      hitType: 'pageview',
      page: `${document.location.hostname}/${window.location.pathname + window.location.search}`,
    });
  };

  return { trackPageView };
}