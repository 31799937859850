export const SolutionKeys = [
  {
    title: "Lojas Wine",
    key: 73000409648,
  },
  {
    title: "Atendimento",
    key: 73000409700,
  },
  {
    title: "Assinaturas",
    key: 73000339761,
  },
  {
    title: "Pedidos",
    key: 73000339842,
  },
  {
    title: "Termos e Regulamentos",
    key: 73000410926,
  },
  {
    title: "Cadastro e pagamento",
    key: 73000410908,
  },
  {
    title: "WineUP",
    key: 73000409707,
  },
  {
    title: "Turbine",
    key: 73000409625,
  },
  {
    title: "Entrega expressa",
    key: 73000410925,
  },
  {
    title: "Indique amigos",
    key: 73000409466,
  },
  {
    title: "Desafio Wine",
    key: 73000410915,
  },
  {
    title: "Promoções e Cupons",
    key: 73000410900,
  },
  {
    title: "Sobre a Wine",
    key: 73000409647,
  },
  {
    title: "Termos e Regulamentos 2023",
    key: 73000566928,
  },
  {
    title: "Prêmio Reclame Aqui 2024",
    key: 73000570670,
  },


  // {
  //   title: "Cancelamento",
  //   key: 73000410904,
  // }, // this hiding is temporary
  // {
  //   title: "Entrega",
  //   key: 73000339799,
  // }, // this hiding is temporary
  // {
  //   title: "WineBox seu jeito",
  //   key: 73000409459,
  // }, // this hiding is temporary
  // {
  //   title: "Imposto Zero",
  //   key: 73000479001,
  // },
  // {
  //   title: "Pendura Wine",
  //   key: 73000559109,
  // },
  // {
  //   title: "Black Wine",
  //   key: 73000562099,
  // }
];

export const SolutionIgnored = {
  title: "Base de conhecimento",
  key: 73000263365,
};

export const WineBrTag = [];
export const WineMxTag = ["Wine México"];
